
    font-weight: var(--fontWeightSemiBold);
    font-size: 18px;
    line-height: 24px;
    
    margin-top: 16px;
    margin-bottom: 14px;

    @media (--viewportMedium) {
      
      margin-top: 16px;
      margin-bottom: 16px;
    }
  