
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightSemiBold);
    font-size: 13px;
    line-height: 18px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      font-size: 13px;
      line-height: 20px;
    }
  