
    @apply --marketplaceButtonStyles;

    background-color: var(--matterColorLight);
    color: var(--matterColor);

    
    padding: 18px 0 0 0;

    border-width: 1px;
    border-style: solid;
    border-color: var(--matterColorNegative);

    &:hover,
    &:focus {
      background-color: var(--matterColorLight);
      border-color: var(--matterColorAnti);
    }
    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      border-color: var(--matterColorNegative);
    }
  